import {Link} from "react-router-dom";

export default function Contacts() {
    return (
        <main style={{ padding: "1rem 0" }}>
            <section className="article">
                <article>
                    <h1>Контакты!</h1>
                    <p className="introduction">Сетевое издание «Новые Оперные Технологии», not.media </p>
                    <p className="introduction">Учредитель и главный редактор: Вишневский А. С. </p>
                    <p className="introduction">Email: avishnevsky@not.media </p>
                    <p className="introduction">Тел.: +7(968)469-89-87 </p>
                </article>

            </section>
            <nav className="bottom-menu">
                <Link to="/contacts">Контакты</Link>
            </nav>
        </main>
    );
}