import {Link, Outlet, useParams} from "react-router-dom";
import { getPublications } from "../data";

export default function Interview() {
    let params = useParams();
    let publications = getPublications();
    return (
        <main>
            <section className="article">
                <div className="article-side">
                    <nav className="side-menu">
                        {publications.map((p) => (
                            <Link className="side-menu__link"
                                to={`/interview/${p.short_name}`}
                                key={p.short_name}
                            >
                                {params.interviewId ? p.name : p.date + " / " + p.name + " / " + p.subcategory}
                            </Link>
                        ))}
                    </nav>
                    <Outlet />
                </div>
            </section>
            <nav className="bottom-menu">
                <Link to="/contacts">Контакты</Link>
            </nav>
        </main>
    );
}