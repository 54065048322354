let publications = [
    {
        id: 1,
        short_name: "luiza_zelenina",
        name: "Луиза Зеленина",
        title: "О смыслах, музыке и поэзии с Луизой Зелениной. Интервью",
        category: "Русская культура",
        subcategory: "Современная поэзия",
        date: "6 июня 2022",
        introduction: "Сегодня мы поговорим о поэзии и музыке с членом Российского Творческого Союза Работников " +
            " Культуры, Союза Писателей, обладателем медали Сергия Радонежского «За подвижничество и" +
            " общественное служение», автором пяти сборников стихов, номинантом литературных премий Мира" +
            " 2018 и премии Наследие 2014 и 2015 годов – Луизой Зелениной.",
        paragraphs: [
            {   class: "correspondent",
                person: "Корреспондент «Новые Оперные Технологии»",
                text: "Здравствуйте, Луиза! Расскажите, как Вы познакомились с поэзией?"},
            {   class: "respondent",
                person: "Луиза Зеленина",
                text: "Здравствуйте! Я познакомилась с поэзией, наверное, как все люди, которые выросли в " +
                    " Советском Союзе. Мы с детства в семье со стихами росли и в школе. А дальше это стало " +
                    " любовью всей жизни."},

            {   class: "correspondent",
                person: "Кор. «Н.О.Т.»",
                text: "Вы помните, как написали свое первое стихотворение? Что побудило Вас начать писать?"},
            {   class: "respondent",
                person: "Луиза Зеленина",
                text: "Начала писать очень" +
                    " поздно, будучи совершенно взрослым человеком. Я психолог по образованию, кандидат" +
                    " психологических наук. Всегда считала, что это дело избранных – писать стихи. Моя профессия" +
                    " – работа с людьми, судьбы людей, размышления об этих судьбах, анализ, беседы с собой и" +
                    " самоирония. Наверное, это и привело меня к тому, что я начала писать стихи и прозу. У меня," +
                    " если позволите, есть стихи-размышления о том, как я начала писать. Позволите, да?"},

            {   class: "correspondent",
                person: "Кор. «Н.О.Т.»",
                text: "Конечно!"},
            {   class: "respondent",
                person: "Луиза Зеленина",
                text: "\n" +
                    "Читать стихи – прекрасное занятие. \n" +
                    "Писать стихи – быть должен божий дар. \n" +
                    "Так я считала, думая о том, \n" +
                    "Что не дано быть каждому поэтом. \n" +
                    "В душевных муках, ведь рождаются стихи. \n" +
                    "И пережить ту боль дано не многим. \n" +
                    "А коль немногим, избраны они, \n" +
                    "Не ведая порой за муки иль грехи. \n" +
                    "«Писать самой?» - Смеялась я, шутя. \n" +
                    "Увы, мне Бог таланта не дал. \n" +
                    "А если был талант, То Он, меня любя, \n" +
                    "Уж очень глубоко его упрятал. \n" +
                    "Но что-то вдруг произошло со мной: \n" +
                    "В душе испуг и страшный бой. \n" +
                    "Сами собой в стихи ложатся строчки, \n" +
                    "И неуверенные робкие слова \n" +
                    "Рифмуются. И уж не видно точки. \n" +
                    "И мыслей рой, я мучаюсь, ужель я солгала. \n" +
                    "И мой талант, упрятанный глубоко, \n" +
                    "Само собой случайно я нашла. \n" +
                    "Судьба моя, не будь ко мне жестока…! \n"},
            {   class: "respondent",
                person: "Луиза Зеленина",
                text: "Вот, думаю, ответила, как я к этому пришла."},

            {   class: "correspondent",
                person: "Кор. «Н.О.Т.»",
                text: "Спасибо! Ваши первые произведения тоже опубликованы, они в сборниках стихов?"},
            {   class: "respondent",
                person: "Луиза Зеленина",
                text: "Я очень долго не" +
                    " публиковала. Начала писать в 2000 году, а 2010 вышел первый сборник. Он вышел очень быстро," +
                    " потому что на три моих стихотворения были написаны песни канадским композитором, и нужно" +
                    " было срочно сохранить авторство. Это не основная моя профессия. Это хобби."},

            {   class: "correspondent",
                person: "Кор. «Н.О.Т.»",
                text: "Много времени удается уделять творчеству?"},
            {   class: "respondent",
                person: "Луиза Зеленина",
                text: "Если пишется, то тут не игра времени. Ты можешь идти, и в дороге вдруг строчка пошла, и ты" +
                    " пишешь. Я никогда не сажусь специально: вот сейчас я напишу шедевр. Такого у меня никогда" +
                    " не было. Это внутренняя потребность высказаться. Я рада, что мои стихи становятся интересны" +
                    " людям. Стихи – это как бы разговор самой с собой и с теми людьми, с которыми по профессии" +
                    " я общалась. Я очень много лет работала в социальной сфере. Эти беседы помогли мне стать тем," +
                    " кем стала."},

            {   class: "correspondent",
                person: "Кор. «Н.О.Т.»",
                text: "А вот интересно, как сейчас пишутся, рождаются стихи? На бумаге или сразу на компьютере," +
                    "как у Вас?"},
            {   class: "respondent",
                person: "Луиза Зеленина",
                text: "Мне сейчас удобно печатать, потому что я вижу текст перед собой. Но если просыпаюсь ночью," +
                    " под рукой обязательно ручка и карандаш, чтобы хоть пару строк записать. Потому что," +
                    " проснувшись утром, можно не вспомнить. У меня, по крайней мере, так. Я люблю перед собой" +
                    " видеть текст."},

            {   class: "correspondent",
                person: "Кор. «Н.О.Т.»",
                text: "Золотой век русской поэзии был двести лет назад, серебряный сто лет назад. А какой Вы" +
                    " видите поэзию в двадцать первом столетии?"},
            {   class: "respondent",
                person: "Луиза Зеленина",
                text: "Вы не поверите, но в 2021 году в журнале «Поэзия. Двадцать первый век» мои стихи попали в" +
                    " раздел «поэты уранового века». Я не задумывалась над этим."},

            {   class: "correspondent",
                person: "Кор. «Н.О.Т.»",
                text: "Похоже на синтез научных достижений с поэзией, физиков и лириков."},
            {   class: "respondent",
                person: "Луиза Зеленина",
                text: "Да, наверное. Это всегда было свойственно физикам и лирикам."},

            {   class: "correspondent",
                person: "Кор. «Н.О.Т.»",
                text: "Недавно оперная певица Ольга Жигмитова записала песню по вашему стихотворению «Офицерская" +
                    " честь». Что вдохновило Вас на это стихотворение? Когда оно появилось?"},
            {   class: "respondent",
                person: "Луиза Зеленина",
                text: "Это стихотворение у меня появилось в мае 2011 года. Вы, наверное, помните, что армия в это" +
                    " время переживала достаточно сложный период. Если вы читали мое стихотворение, то в основе" +
                    " его спор с человеком, который не верит в нашу армию, не верит в офицеров. А я доказываю" +
                    " обратное." +
                    "\n\n" +
                    "«Я не верю всем тем, кто мне так говорит: \n" +
                    "Сердце этих людей бескорыстьем горит… \n" +
                    "И профессия есть, чтоб других защищать, \n" +
                    "Ценой собственной жизни, покой сберегать». \n\n" +
                    " Вера в нашу армию, в наших офицеров, для которых честь всегда была важна.  Я очень рада," +
                    " что стихи актуальны сегодня и подтвердили то моё восприятие и отношение к этим смелым людям" +
                    " отважной профессии. Я считаю, что честь – это главное для офицера."},

            {   class: "correspondent",
                person: "Кор. «Н.О.Т.»",
                text: "Много песен написано по вашим стихам?"},
            {   class: "respondent",
                person: "Луиза Зеленина",
                text: "Первый был канадский композитор Гарри Рексон. Три песни. Музыку к песне «Офицерская честь»" +
                    " написал композитор Павел Михайлович Толмачёв, заслуженный работник культуры Бурятии. Сама" +
                    " была удивлена, что на мои стихи написана пятьдесят одна песня. Сотрудничала с тринадцатью" +
                    " музыкантами и композиторами. Конечно, не все профессиональные композиторы, но пятьдесят" +
                    " одна песня на мои стихи написана."},

            {   class: "correspondent",
                person: "Кор. «Н.О.Т.»",
                text: "У Вас есть стихи, посвященные оперным певцам и певицам. Как Вы относитесь к оперному" +
                    " искусству, что Вас с ним связывает?"},
            {   class: "respondent",
                person: "Луиза Зеленина",
                text: "Во-первых, я великолепно отношусь к оперному искусству. С любовью, с уважением отношусь и к" +
                    " опере, и к великим музыкантам, оперным певцам. Очень люблю оперу. Конечно же, что может" +
                    " связывать? Только любовь к этому искусству. Есть певцы, с которыми я дружна. Им написаны" +
                    " стихи-посвящения. Бывает, попав на очередной концерт, ты по - новому проживаешь вместе с" +
                    " исполнителем песню, арию из опер. Так были написаны и стихи народному артисту Чувашии" +
                    " оперному певцу Валерию Иванову (баритон). Он в свое время входил в двенадцать лучших" +
                    " «чёрных баритонов» мира. Ему посвящено стихотворение «Лучший черный баритон». Молодой," +
                    " очаровательной певице Елизавете Канаузовой (сопрано) – «Под чарующей лаской твоею…»." +
                    " Романсы в её исполнении тронули душу и захотелось написать стихи-посвящение. Не каждому" +
                    " пишешь стихи. В 2013 году в Новой опере была премьера Пиковой дамы, и в течение нескольких" +
                    " дней меня переполняли эмоции. Родилось стихотворение «Премьера Пиковой Дамы». Есть стихи," +
                    " посвященные заслуженной артистке РФ Галине Кашиной «Песнь – это жизнь моя» и заслуженной" +
                    " артистке Чувашии певице Елене Соловьёвой (сопрано)- «Ода Соловью». Ну и потом, конечно же," +
                    " посвящение композиторам Гарри Рексону и Вячеславу Семёнову... С каждым написано несколько" +
                    " песен. К сожалению, эти два композитора уже покинули наш мир. Но в память о них живут наши" +
                    " песни. Песня на музыку Вячеслава Семёнова «Русская кадриль» в 2018 году звучала в Колонном" +
                    " зале Дома Союзов. Песни продолжают жить, летают и это хорошо. Память об этих людях" +
                    " жива."},

            {   class: "correspondent",
                person: "Кор. «Н.О.Т.»",
                text: "В стихотворении «Премьера Пиковой дамы» Вы делитесь впечатлениями о современной опере." +
                    " Отдаете ли Вы предпочтение классическим оперным постановкам или современным, отходящим от" +
                    " канонов?"},
            {   class: "respondent",
                person: "Луиза Зеленина",
                text: "Я предпочитаю, конечно, классику. Я люблю классику. Так случилось, что я попала на эту" +
                    " премьеру. И, честно вам скажу, половина зала вставала, кричала «Позор!» и выходила." +
                    " Я решила понять, что же произошло? Что хотели показать режиссеры-постановщики? Увидеть" +
                    " финал. Впечатление было неоднозначным. Но в стихах я поделилась лучшим, тем что мне" +
                    " понравилось. Понравились декорации, оформление, подача. Мне интересно узнавать новое." +
                    " В жизни я научилась принимать. Нельзя сразу отвергать всё новое, непонятное, неожиданное." +
                    " Да, я люблю классику. Я предпочитаю, всё-таки, традиционные оперы и балеты. Но при этом я" +
                    " понимаю, что новое входит в нашу жизнь. Мы живём в этом мире. И полностью отрицать его" +
                    " нельзя. Надо смотреть, стараться понять взгляд молодых, новых людей, пришедших в это" +
                    " искусство. Так я поделилась своим впечатлением о премьере Пиковой дамы."},

            {   class: "correspondent",
                person: "Кор. «Н.О.Т.»",
                text: "А Вы сами поёте? Был у Вас когда-нибудь опыт занятий вокалом?"},
            {   class: "respondent",
                person: "Луиза Зеленина",
                text: "Нет, к моему большому сожалению. Очень люблю петь, но пою только в кругу самых близких" +
                    " людей за праздничным столом. А хотелось бы, наверное, петь. Всегда ценю талант. Меня" +
                    " окружают талантливые певцы, композиторы, творческие люди. И мне это нравится. Петь - это" +
                    " тоже божий дар! Не всем дано. Это огромный талант! Бог поцеловал…"},

            {   class: "correspondent",
                person: "Кор. «Н.О.Т.»",
                text: "Согласен. Для того, чтобы петь нужен талант, и при этом певцы долго учатся. А как в" +
                    " отношении стихов? Любой ли человек может научиться писать стихи? Или необходима природная" +
                    " предрасположенность, врождённый талант, может быть, развитые духовные качества?"},
            {   class: "respondent",
                person: "Луиза Зеленина",
                text: "Научиться можно, наверное, всему. Но одно дело – человек пишет, когда его так взволновало," +
                    " когда он пережил что-то, он выражает свои мысли и эмоции в стихах и прозе. Или, когда" +
                    " просто рифмует. Вот вы спросили меня, как я пришла к написанию стихов. У нас на работе" +
                    " была женщина, которая быстро могла написать стих на любую тему. Сначала мне это нравилось," +
                    " а потом я сказала себе – «нет».  Я понимала, что приятельница не передает моё состояние" +
                    " души. В ответ: «Пиши сама!». Вот в одной из таких ситуаций, начала писать сама. Я ей до" +
                    " сих пор говорю, что она “виновата” в том, что я чего-то достигла. Трудно писать, если это" +
                    " тобой не прочувствовано, или не пережито.  Считаю, что надо писать сердцем."},

            {   class: "correspondent",
                person: "Кор. «Н.О.Т.»",
                text: "Рифмованные строчки ещё не стихи."},
            {   class: "respondent",
                person: "Луиза Зеленина",
                text: "Абсолютно! Возможно, поэтому не так много гениальных поэтов. Их единицы. Суть в том, что" +
                    " это люди, в которых талант был от Бога, поцелованные в макушку. Я себя к этим людям не" +
                    " отношу и считаю любителем в поэзии. А те люди - редкие самородки."},

            {   class: "correspondent",
                person: "Кор. «Н.О.Т.»",
                text: "Вынашивание стихов – это напряжённая психическая работа. Бывают ли в таком творческом" +
                    " процессе кризисы? Как Вы с ними справляетесь?"},
            {   class: "respondent",
                person: "Луиза Зеленина",
                text: "В одном из моих стихотворений написано: «Когда не пишется – молчу... Вселенной значит так" +
                    " угодно...». Нельзя придумывать!  Для меня это внутренняя потребность высказаться," +
                    " поделиться чем-то сокровенным. Если это не сокровенное или то, что говорят все, смысла не" +
                    " вижу. Кризис, на мой взгляд, есть у всех. Где-то надо помолчать. Бывает так. А потом новый" +
                    " взлёт! У меня был великолепный 2013 год. Я до сих пор считаю, что строчки летели, в день" +
                    " писалось много, песни были написаны в том же году, книги издавались. Не мешала и сильная" +
                    " загрузка на работе. Пока дошёл до работы, уже сочинил. Бывают и случаи, которые помогают" +
                    " писать стихи. Моей визитной карточкой в интернете стал романс «Мадам, мы разве с Вами" +
                    " незнакомы?» в исполнении Дмитрия Панькова. Вы знаете, как я его написала? Подошла к" +
                    " перекрёстку, стою у светофора. Жду. Вдруг подходит молодой человек. Все стоят, ждут," +
                    " а ему некогда. Он меня оттолкнул и говорит: «Подвинься, мадам», и пошел. А у меня родились" +
                    " стихи. Я всегда его вспоминаю с благодарностью и говорю, если вас где-то толкнули," +
                    " оскорбили, это ещё не значит, что всё плохо. В моём арсенале появилась замечательная" +
                    " песня."},

            {   class: "correspondent",
                person: "Кор. «Н.О.Т.»",
                text: "Если нашу с Вами беседу читают начинающие поэты и поэтессы, что бы Вы могли им посоветовать?"},
            {   class: "respondent",
                person: "Луиза Зеленина",
                text: "Я всем людям советую верить в себя! Идти своим путём! И если поэзия, действительно," +
                    " главное в вашей жизни, творите! Если вы не можете не писать, это может быть проза, это" +
                    " могут быть стихи, значит, надо писать. А если можете не писать, то не пишите. Значит," +
                    " есть люди, которые могут, наверное, лучше вас это делать. То есть это должно быть" +
                    " внутренней необходимостью. И понимать, ради чего ты это делаешь."},

            {   class: "correspondent",
                person: "Кор. «Н.О.Т.»",
                text: "Спасибо большое за беседу и уделённое время!"},
            {   class: "respondent",
                person: "Луиза Зеленина",
                text: "Спасибо вам за интерес к моему творчеству!"},
            ]
    }
];

export function getPublications() {
    return publications;
}

export function getPublication(short_name) {
    return publications.find(
        (p) => p.short_name === short_name
    );
}