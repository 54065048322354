import ReactDOM from "react-dom/client";
import {
    BrowserRouter,
    Routes,
    Route, Link,
} from "react-router-dom";
import App from "./App";
import Categories from "./routes/categories";
import Interview from "./routes/interview";
import Main from "./routes/main";
import Publication from "./routes/publication";
import Contacts from "./routes/contacts";

const root = ReactDOM.createRoot(
    document.getElementById("root")
);
root.render(
    <BrowserRouter>
        <Routes>
            <Route path="/" element={<App />}>
                <Route path="interview" element={<Interview />}>
                    <Route path=":interviewId" element={<Publication />} />
                </Route>
                <Route path="categories" element={<Categories />} />
                <Route path="main" element={<Main />} />
                <Route path="contacts" element={<Contacts />} />
                <Route
                    path="*"
                    element={
                        <main>
                            <section className="article">
                            <p>К сожалению, материал по этой ссылке недоступен</p>
                            </section>
                            <nav className="bottom-menu"
                                style={{
                                    borderBottom: "solid 1px",
                                    borderTop: "solid 1px",
                                    paddingBottom: "1rem",
                                    paddingTop: "1rem",
                                }}
                            >
                                <Link to="/main">Главная</Link> |{" "}
                                <Link to="/interview">Интервью</Link> |{" "}
                                <Link to="/categories">Рубрики</Link>

                            </nav>
                        </main>
                    }
                />
            </Route>
        </Routes>
    </BrowserRouter>
);