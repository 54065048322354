import { useParams } from "react-router-dom";
import { getPublication } from "../data";

export default function Publication() {
    let params = useParams();
    let publication = getPublication(params.interviewId, 'luiza_zelenina');
    return (
        <article>
            <h2>{publication.title}</h2>
            <h3>{publication.subcategory}</h3>
            <p>Сетевое издание Новые Оперные Технологии / {publication.date}</p>
            <p>{publication.introduction}</p>
            <div>{
                publication.paragraphs.map((paragraph, index) => {
                    return (
                        <p key={index}>
                            <span className={paragraph.class}>{paragraph.person}: </span>
                            {paragraph.text.split("\n").map(function(item, idx) {
                            return (
                                <span key={idx}>{item}<br/></span>
                            )
                        })}</p>
                );})
            }
            </div>
        </article>
    );
}
