import { Outlet, Link } from "react-router-dom"
import React, { useState, useEffect } from 'react';
import './App.css'

export default function App() {
    useEffect(() => {
        document.title = "НотМедиа"
    }, []);

    return (
        <div>
            <header>
                <h1>Новые оперные технологии</h1>
            </header>
            <main>
                <nav className="top-menu">
                    <Link to="/main">Главная</Link> |{" "}
                    <Link to="/interview">Интервью</Link> |{" "}
                    <Link to="/categories">Рубрики</Link>
                </nav>
                <Outlet />
            </main>
            <footer>
                <p>Сетевое издание «Новые Оперные Технологии», <span className="footer-email">not.media</span></p>
                <p>Номер свидетельства ЭЛ № ФС 77-79180 от 22.09.2020</p>
                <p>Контент сайта соответствует возрастному ограничению <span className="footer-age">16+ </span>согласно
                    Российской Системе Возрастных Рейтингов (РСВР).</p>
                <p>Все права на материалы, опубликованные на сайте <span className="footer-email">not.media</span>,
                    принадлежат редакции
                и охраняются в соответствии с законодательством РФ.</p>
                <p>Использование материалов, опубликованных на сайте <span className="footer-email">not.media</span>,
                    допускается только с письменного разрешения редакции
                портала и с обязательной прямой открытой для индексирования гиперссылкой на страницу, с которой материал
                заимствован.</p>
                <p>При цитировании и копировании материалов «Новые Оперные Технологии» активная ссылка на
                портал обязательна</p>
            </footer>

        </div>
    );
}