import {Link} from "react-router-dom";

export default function Main() {
    return (
        <main style={{ padding: "1rem 0" }}>
            <section className="article">
                <article>
                    <h1>Здравствуйте!</h1>
                    <p className="introduction">Добро пожаловать на сайт
                        электронного издания Новые Оперные Технологии </p>
                    <p className="introduction">Опера - искусство синтетическое, объединяющее в себе музыку, театр,
                        танец, изобразительное искусство, пение. Вместе это работа композиторов, режиссеров, целого
                        оркестра музыкантов, танцоров, певцов, осветителей, костюмеров, гримёров... Работа каждого из
                        них - важнейший элемент общей картины. </p>
                    <p className="introduction">Мы хотели бы, чтобы наш интернет-проект Новые Оперные Технологии стал
                        гидом в мире оперы и искусства. Здесь на нашем сайте вы найдете материалы интересные как
                        профессионалам так и любителям оперы. </p>
                    <p className="introduction">Наша цель - популяризация оперы, а также поддержка молодых
                        талантов - будущего нашей культуры. Мы хотим напомнить о реальной красоте искусства
                        и объединить усилия людей, чувствующих красоту и живущих ею. </p>
                    <p className="introduction">Вас ожидают материалы о лучших образцах музыки, исполнителях, концертных
                        площадках, технологиях и секретах, закулисной "кухне"... И многое другое. Будет интересно! </p>
                    <p className="introduction">С уважением, </p>
                    <img className="signature" src="pictures/signature.png" />
                    <p>Главный редактор<br />Электронного издания<br />Новые Оперные Технологии</p>


                </article>

            </section>
            <nav className="bottom-menu">
                <Link to="/contacts">Контакты</Link>
            </nav>
        </main>
    );
}