import {Link} from "react-router-dom";

export default function Categories() {
    return (
        <main style={{ padding: "1rem 0" }}>
            <section className="article">
                <h3>Современная поэзия</h3>
                <Link to="/interview/luiza_zelenina">О смыслах, музыке и поэзии с Луизой Зелениной. Интервью</Link>
            </section>
            <nav className="bottom-menu">
                <Link to="/contacts">Контакты</Link>
            </nav>
        </main>
    );
}